import { useState } from 'react';
import getUniqueId from '../../utils/getUniqueId';
import { Folder, InputEvent } from '../../types';
import { addFolder } from '../../utils/dbActions';
import { useAuthContext } from '../../context/AuthContext';
import { Input } from 'antd';
import GlobalModal from './GlobalModal';

export default function NewFolder({ closeModal }: { closeModal: () => void }) {
  const { setUpdateData, contextData, setError } = useAuthContext();

  const [newFolderName, setNewFolderName] = useState('');
  const [folderError, setFolderError] = useState(false);
  // CREATE FOLDER
  function createFolder() {
    if (!newFolderName) {
      setFolderError(true);
      return;
    }
    setFolderError(false);
    const id = getUniqueId();
    const newFolder: Folder = { name: newFolderName, id, created: new Date() };
    const thenActions = () => {
      // setIsModalOpen(false);
    };
    addFolder({ user: contextData?.user, newFolder, thenActions, callUpdate: () => setUpdateData(true), onError: () => setError('Could not add folder') });
  }
  const modal = {
    title: 'New Folder Name',
    onOk: createFolder,
    okText: 'Create Folder',
    content: (
      <Input
        onChange={(e: InputEvent) => {
          setNewFolderName(e.target.value);
          setFolderError(false);
        }}
        status={folderError ? 'error' : ''}
        name="folderName"
        style={{ margin: '.7em 0 2em' }}
        placeholder="Folder Name"
      />
    ),
  };
  return <GlobalModal {...modal} closeModal={closeModal} />;
}
