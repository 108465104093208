import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import PageTemplate from './pages/PageTemplate';
import FolderView from './pages/FolderView';
import ProjectView from './pages/ProjectView';
import MyProjects from './pages/MyProjects';
import { ReactNode } from 'react';
import { AuthContextProvider } from './context/AuthContext';
import { ProjectContextProvider } from './context/ProjectContext';

export default function App() {
  if (window.location.pathname !== '/') {
    document.title = 'NeedleWerk';
  }

  const getPage = (page: ReactNode) => {
    return (
      <ProjectContextProvider>
        <PageTemplate>{page}</PageTemplate>
      </ProjectContextProvider>
    );
  };

  return (
    <AuthContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index={true} element={<Home />} />
          </Route>
          <Route path="/my-projects">
            <Route index={true} element={getPage(<MyProjects />)} errorElement={<>error</>} />
            <Route path="add" element={getPage(<ProjectView />)} />
            <Route path="view/:projectId" element={getPage(<ProjectView />)} />
            <Route path="folder">
              <Route index={true} path=":folderId" element={getPage(<FolderView />)} />
              <Route path=":folderId/:projectId" element={getPage(<ProjectView />)} />
              <Route path=":folderId/add" element={getPage(<ProjectView />)} />
            </Route>
          </Route>
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </AuthContextProvider>
  );
}
