import { LegacyRef } from 'react';
import { Row, InputEvent } from '../../types';
import { InputRef } from 'antd';

type GetInputProps = {
  type: 'label' | 'value';
  row: Row;
  i: number;
  focusRef?: LegacyRef<InputRef>;
  handleRowChange: (e: InputEvent, i: number, field: string) => void;
};

// INPUT PROPS
export function getInputProps({ type, row, i, focusRef, handleRowChange }: GetInputProps) {
  let inputRef = null;
  if (type === 'label' && row.type === 'row') {
    inputRef = focusRef;
  } else if (type === 'value' && row.type) {
    inputRef = focusRef;
  }

  let props: any = {
    name: `row-${type}-${i}`,
    value: row[type],
    onChange: (e: InputEvent) => handleRowChange(e, i, type),
    size: row.type === 'header' ? 'large' : 'default',
  };
  if (inputRef) {
    props = { ...props, ref: inputRef };
  }
  return props;
}
