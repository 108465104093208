import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { Project } from '../types';
import { useLocation, useParams } from 'react-router-dom';
import { useAuthContext } from './AuthContext';
import { updateProject } from '../utils/dbActions';
import getUniqueId from '../utils/getUniqueId';

type ProjectContextType = {
  project: Project;
  setProject: Dispatch<SetStateAction<Project>>;
  onProjectChange: (p: Project) => void;
  onDoneToggle: (p: Project) => void;
  unsavedChanges: boolean;
  setUnsavedChanges: Dispatch<SetStateAction<boolean>>;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  unsavedToggles: boolean;
  setUnsavedToggles: Dispatch<SetStateAction<boolean>>;
  saveProject: (thenActions?: () => void) => void;
  isMobile: boolean;
  tabHeight: number;
};

const initialProject: Project = {
  name: '',
  id: getUniqueId(),
  pattern: [],
  specs: [],
  abbreviations: [],
  created: new Date(),
};
const initialState: ProjectContextType = {
  project: initialProject,
  setProject: () => {},
  onProjectChange: () => {},
  onDoneToggle: () => {},
  saveProject: () => {},
  setUnsavedChanges: () => {},
  unsavedChanges: false,
  setUnsavedToggles: () => {},
  unsavedToggles: false,
  setEditMode: () => {},
  editMode: false,
  isMobile: false,
  tabHeight: 0,
};

const ProjectContext = createContext<ProjectContextType | undefined>(initialState);

export const ProjectContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [project, setProject] = useState<Project>(initialProject);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [unsavedToggles, setUnsavedToggles] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [tabHeight, setTabHeight] = useState(window.innerHeight - (isMobile ? 185 : 130));

  let { projectId } = useParams();
  const { contextData, setUpdateData, setSuccess } = useAuthContext();
  const location = useLocation();
  const onAdd = location.pathname.includes('add');

  const onSetProject = useCallback(
    (projectId?: string) => {
      if (projectId) {
        const allProjects = contextData?.allProjects;
        const currProject = allProjects?.find((p: Project) => p.id === projectId) as Project;
        setProject(currProject);
      } else {
        setProject({ ...initialProject, id: `${Date.now()}` });
      }
    },
    [contextData?.allProjects],
  );

  useEffect(() => {
    onSetProject(projectId);
  }, [projectId, onAdd, onSetProject]);

  const onProjectChange = (p: Project) => {
    setUnsavedChanges(true);
    setProject(p);
  };

  const onDoneToggle = (p: Project) => {
    setUnsavedToggles(true);
    setProject(p);
  };

  const saveProject = (_thenActions?: () => void) => {
    const saved: Project = { ...project, lastSaved: new Date() };
    const thenActions = () => {
      if (_thenActions) {
        _thenActions();
      }
      setSuccess('Project saved');
    };
    updateProject({ user: contextData?.user, project: saved, thenActions, callUpdate: () => setUpdateData(true) });
  };

  // IS MOBILE

  const callback = () => {
    console.log({ h: window.innerHeight });

    if (window.innerHeight !== windowHeight) {
      setTabHeight(window.innerHeight - (isMobile ? 185 : 130));
      setWindowHeight(window.innerHeight);
    }
    setIsMobile(window.innerWidth <= 768);
  };

  const observer = new ResizeObserver(callback);
  observer.observe(document.body);

  const contextValue = {
    project,
    setProject,
    onProjectChange,
    unsavedChanges,
    setUnsavedChanges,
    unsavedToggles,
    setUnsavedToggles,
    onDoneToggle,
    saveProject,
    editMode,
    setEditMode,
    isMobile,
    tabHeight,
  };
  // console.log({ contextData, children });

  return <ProjectContext.Provider value={contextValue}>{children}</ProjectContext.Provider>;
};

export function useProjectContext(): ProjectContextType {
  const context = useContext(ProjectContext) as ProjectContextType;
  if (context === undefined) {
    throw new Error('context is undefined');
  }
  return context;
}
