import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { FolderSimplePlus, FilePlus, SquaresFour, List, UserCircle, SignOut } from '@phosphor-icons/react';
import { MenuProps, Button, Menu, Layout, Dropdown } from 'antd';
import '../styles/Header.scss';
import { useProjectContext } from '../context/ProjectContext';
import Sider from 'antd/es/layout/Sider';
import { useState } from 'react';
import { EditUserModal, NewFolderModal, SignoutModal, UnsavedChangesModal } from './modals';

const { Header } = Layout;

export default function SiteHeader({ siderBarWidth }: { siderBarWidth: number }) {
  const navigate = useNavigate();
  let location = useLocation();
  const onAddView = location.pathname.includes('add');

  const { isMobile } = useProjectContext();
  const { projectId, folderId } = useParams();
  const { unsavedChanges, unsavedToggles, saveProject } = useProjectContext();

  const [modalType, setModalType] = useState('');

  // NAV HOME
  const navigateHome = () => {
    if (unsavedChanges) {
      setModalType('unsavedChanges');
    } else if (unsavedToggles) {
      const thenActions = () => {
        navigate('/my-projects');
      };
      saveProject(thenActions);
    } else {
      navigate('/my-projects');
    }
  };
  const closeModal = () => {
    setModalType('');
  };

  const items: MenuProps['items'] = [];

  // USER GROUP
  const userProfile = {
    key: 'userProfile',
    label: 'User Profile',
    icon: <UserCircle size={20} weight="fill" />,
    onClick: () => {
      setModalType('editUser');
    },
  };
  // SIGN OUT
  const signOut = {
    key: 'signOut',
    label: 'Sign Out',
    icon: <SignOut size={20} weight="fill" />,
    onClick: () => {
      setModalType('signout');
    },
  };
  // ADD PROJECT
  const addProject = {
    key: 'project',
    label: 'Add Project',
    icon: <FilePlus size={16} weight="fill" />,
    onClick: () => navigate('add', { relative: 'path' }),
  };
  // ADD FOLDER
  const addFolder = {
    key: 'folder',
    label: 'Add Folder',
    icon: <FolderSimplePlus size={16} weight="fill" />,
    onClick: () => setModalType('newFolder'),
  };
  // MY PROJECTS
  const myProjects = {
    key: 'home',
    label: 'My Projects',
    icon: <SquaresFour size={16} weight="fill" />,
    onClick: navigateHome,
  };

  if (projectId || onAddView) {
    items.push(myProjects);
  } else {
    items.push(addProject);
    if (!folderId) {
      items.push(addFolder);
    }
  }
  if (isMobile) {
    items.push(userProfile);
    items.push(signOut);
  }

  const siderStyle: React.CSSProperties = {
    overflow: 'auto',
    position: 'fixed',
    insetInlineStart: 0,
    top: 0,
    bottom: 0,
    scrollbarWidth: 'thin',
    scrollbarColor: 'unset',
  };
  const Logo = (
    <button className="logo" onClick={navigateHome}>
      <img src="/assets/logo.png" alt="logo" />
    </button>
  );

  return (
    <>
      {!isMobile ? (
        <Sider className="sider" style={siderStyle} width={siderBarWidth}>
          {/* collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} */}
          {Logo}

          <Menu mode="inline" items={items} />

          <Button onClick={() => setModalType('signout')} icon={<SignOut size={20} weight="fill" />} className="sign-out-btn" type="primary">
            Sign out
          </Button>
        </Sider>
      ) : (
        <Header className="mobile-header">
          {Logo}
          <Dropdown menu={{ items }}>
            <Button type="text">
              <List color="#fff" size={24} />
            </Button>
          </Dropdown>
        </Header>
      )}
      {/* MODALS */}
      {modalType === 'newFolder' && <NewFolderModal closeModal={closeModal} />}
      {modalType === 'signout' && <SignoutModal closeModal={closeModal} />}
      {modalType === 'unsavedChanges' && <UnsavedChangesModal closeModal={closeModal} />}
      {modalType === 'editUser' && <EditUserModal closeModal={closeModal} />}
    </>
  );
}
