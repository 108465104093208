import { Button, Modal } from 'antd';
import { ReactNode } from 'react';

type ModalType = {
  title: string | ReactNode;
  // setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  closeModal: () => void;
  onCancel?: () => void;
  onOk: () => void;
  okText: string;
  cancelText?: string;
  content?: ReactNode;
  disableOk?: boolean;
};

export default function GlobalModal({ title, onOk, okText, onCancel, cancelText, content, closeModal, disableOk }: ModalType) {
  const cancelHandler = () => {
    if (onCancel) {
      onCancel();
    }
    closeModal();
  };
  const onOkHandler = () => {
    onOk();
    closeModal();
  };

  return (
    <Modal
      title={title}
      open={true}
      onCancel={cancelHandler}
      closeIcon={null}
      footer={[
        <Button onClick={onOkHandler} type="primary" key="1" disabled={disableOk}>
          {okText}
        </Button>,
        <Button onClick={cancelHandler} key="2">
          {cancelText || 'Cancel'}
        </Button>,
      ]}
    >
      {content}
    </Modal>
  );
}
