import '../styles/Knitting.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { Folder, Project, ProjectListItemProps, ResponseData } from '../types';
import { useAuthContext } from '../context/AuthContext';
import { ProjectListItem } from '../components/project';
import { Button } from 'antd';
import { Plus } from '@phosphor-icons/react';

export default function FolderView() {
  const { contextData } = useAuthContext();
  let { folderId } = useParams();
  const navigate = useNavigate();

  const { allFolders, allProjects } = contextData as ResponseData;
  const folder: Folder | null = allFolders.find((f: Folder) => f.id === folderId) || null;
  const projects = allProjects.filter((p: Project) => p?.folder === folderId);

  if (!folder) {
    return <>no folder found</>;
  }

  return (
    <div className="container">
      {/* PROJECTS  */}
      {projects.length ? (
        <>
          {projects.map((project: Project) => {
            const props: ProjectListItemProps = { item: project, allFolders };
            return <ProjectListItem {...props} key={project.id} />;
          })}
        </>
      ) : (
        <Button onClick={() => navigate('add', { relative: 'path' })} icon={<Plus size={24} weight="bold" />} type="dashed" className="add-new-project">
          Add new project
        </Button>
      )}
    </div>
  );
}
