import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import '../styles/ProjectView.scss';
import { Project, Row } from '../types';
import { RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ArrowClockwise, DotsThreeOutlineVertical, FilePlus, FloppyDiskBack, Pencil } from '@phosphor-icons/react';
import { Button, Dropdown, Flex, Input, MenuProps, Popconfirm, Tabs } from 'antd';
import { useAuthContext } from '../context/AuthContext';
import { addProject } from '../utils/dbActions';
import { PatternTab } from '../components/project/index';
import { useProjectContext } from '../context/ProjectContext';

export default function ProjectView() {
  const navigate = useNavigate();

  let { folderId } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const onAdd = location.pathname.includes('add');

  const { setUpdateData, contextData, setSuccess, setError } = useAuthContext();
  const { project, setProject, unsavedChanges, setUnsavedChanges, onProjectChange, onDoneToggle, unsavedToggles, saveProject, editMode, setEditMode, isMobile } = useProjectContext();

  const bottomRef = useRef() as RefObject<HTMLDivElement>;
  const [activeTab, setActiveTab] = useState('pattern');

  // TIMER FOR SAVING DONE TOGGLES
  useEffect(() => {
    const interval = setInterval(() => {
      if (unsavedToggles) {
        onSaveProject(true);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [unsavedToggles]);

  // SET EDIT MODE
  useLayoutEffect(() => {
    if (searchParams.get('edit') === 'true') {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [searchParams]);

  // SAVE
  function onSaveProject(isDoneToggleSave?: boolean) {
    if (unsavedChanges || isDoneToggleSave) {
      const thenActions = () => {
        if (!isDoneToggleSave) {
          toggleEditMode();
          setUnsavedChanges(false);
        }
      };
      saveProject(thenActions);
    } else if (!isDoneToggleSave) {
      toggleEditMode();
    }
  }

  // ADD PROJECT
  function onAddProject() {
    const newProject: Project = folderId ? { ...project, folder: folderId, created: new Date() } : project;
    const thenActions = () => {
      setUnsavedChanges(false);
      navigate(`/my-projects${folderId ? `/folder/${folderId}` : ''}`);
      setSuccess('New project added');
    };
    addProject({ user: contextData?.user, newProject, thenActions, callUpdate: () => setUpdateData(true), onError: () => setError('Could not add project') });
  }

  // DONE TOGGLE
  const toggleDone = (i: number) => {
    const currRows: Row[] = [...project.pattern];
    currRows[i].done = !currRows[i].done;
    onDoneToggle({ ...project, pattern: currRows });
  };

  // TOGGLE EDIT MODE
  const toggleEditMode = () => {
    if (!editMode) {
      setSearchParams({
        ...searchParams,
        edit: 'true',
      });
    } else {
      searchParams.delete('editProject');
      setSearchParams({
        ...searchParams,
      });
    }
    setEditMode(!editMode);
  };

  // CONFIRM CB RESET
  const confirmCheckboxReset = () => {
    const currRows = [...project.pattern];
    const resetRows: Row[] = currRows.map((row: Row) => {
      return { ...row, done: false };
    });
    setProject({ ...project, pattern: resetRows });
    onSaveProject(true);
  };

  const items: MenuProps['items'] = [
    {
      key: 'edit',
      // icon: <Pencil size={18} weight="fill" />,
      onClick: () => toggleEditMode(),
      label: (
        <Button icon={<Pencil size={18} weight="fill" />} type="text">
          Edit
        </Button>
      ),
    },
  ];
  if (!onAdd && !editMode && activeTab === 'pattern') {
    items.push({
      key: 'reset',
      label: (
        <Popconfirm title="Reset Checkmarks" description="Are you sure you want to reset?" onConfirm={confirmCheckboxReset} okText="Yes" cancelText="No" placement="bottomLeft">
          <Button danger type="text" icon={<ArrowClockwise size={18} weight="bold" />}>
            Reset Checkmarks
          </Button>
        </Popconfirm>
      ),
    });
  }
  return (
    <>
      {/* PROJECT HEADER */}
      <Flex className="project-header" justify="space-between" align="center">
        {/* PROJECT VIEW */}
        {!onAdd && !editMode && (
          <Dropdown menu={{ items }}>
            <Button icon={<DotsThreeOutlineVertical size={18} weight="fill" />} shape="circle" type="text" className="project-menu" />
          </Dropdown>
        )}
        {/* SAVE  */}
        {!onAdd && editMode && <Button shape="circle" type={'primary'} onClick={() => onSaveProject()} icon={<FloppyDiskBack size={20} weight="fill" />} />}
        {/* ADD  */}
        {onAdd && <Button onClick={() => onAddProject()} disabled={!project.name} type="primary" size="large" icon={<FilePlus size={24} weight="fill" />} shape="circle" />}

        {/* NAME */}
        {onAdd || editMode ? (
          <Input name="projectName" value={project.name} onChange={e => onProjectChange({ ...project, name: e.target.value })} placeholder="Project Name" className="project-name-input" />
        ) : (
          <h2>{project.name}</h2>
        )}
      </Flex>
      {/* TABS */}
      <Tabs
        activeKey={activeTab}
        onChange={tab => setActiveTab(tab)}
        tabBarStyle={{ height: '35px', marginBottom: 0, padding: '0 1em', fontWeight: 600 }}
        type="card"
        items={[
          {
            label: 'Pattern',
            key: 'pattern',
            children: <PatternTab toggleDone={toggleDone} tabType="pattern" />,
          },
          {
            label: 'Specs & Notes',
            key: 'specs',
            children: <PatternTab tabType="specs" />,
          },
          {
            label: 'Abbreviations',
            key: 'abbreviations',
            children: <PatternTab tabType="abbreviations" />,
          },
        ]}
      />
      <div ref={bottomRef}></div>
      {/* <FloatButton onClick={scrollToBottom} shape="circle" style={{ insetInlineStart: 20, insetBlockEnd: 20 }} icon={<ArrowLineDown size={18} weight="bold" />} /> */}
    </>
  );
}
