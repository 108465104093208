import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { Folder, Project, ResponseData, AuthUser } from '../types';
import { collection, getDocs, query } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { onAuthStateChanged, signOut, User } from 'firebase/auth';
import { updateDbItem } from '../utils/dbActions';
import { message } from 'antd';
import { NoticeType } from 'antd/es/message/interface';

type AuthContextType = {
  contextData: ResponseData | null;
  setContextData: Dispatch<SetStateAction<ResponseData | null>>;
  updateData: boolean;
  setUpdateData: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<string>>;
  setSuccess: Dispatch<SetStateAction<string>>;
  loading: boolean;
  onSignOut: () => void;
};

const initialState: AuthContextType = {
  contextData: null,
  setContextData: () => {},
  updateData: false,
  setUpdateData: () => {},
  setError: () => {},
  setSuccess: () => {},
  onSignOut: () => {},
  loading: false,
};

const AuthContext = createContext<AuthContextType | undefined>(initialState);

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [messageApi, msgContextHolder] = message.useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  let localData = null;
  if (localStorage.getItem('data')) {
    localData = JSON.parse(`${localStorage.getItem('data')}`);
  }
  // localStorage.clear();
  const [contextData, setContextData] = useState<ResponseData | null>(localData);

  const getData = async (user: User | null): Promise<ResponseData> => {
    const userQuery = query(collection(db, 'users'));
    const allUserDocs = await getDocs(userQuery);
    const allUsers: AuthUser[] = [];
    allUserDocs.forEach(a => {
      const data: AuthUser = { ...a.data() } as AuthUser;
      allUsers.push(data);
    });

    let authUser: AuthUser | null = null;
    let allProjects: Project[] = [];
    let allFolders: Folder[] = [];

    const foundUser: AuthUser | undefined = allUsers.find((u: AuthUser) => u.id === user?.uid);

    if (foundUser) {
      authUser = foundUser;
      allProjects = foundUser.allProjects;
      allFolders = foundUser.allFolders;
    }

    return { user: authUser, allProjects, allFolders, allUsers };
  };

  // FETCH DATA
  async function fetchData(user?: User) {
    setLoading(true);
    const _user = user || auth.currentUser;
    console.info('fetch data');
    await getData(_user).then(data => {
      const strData = JSON.stringify(data);
      localStorage.setItem('data', strData);

      setContextData(data);
      setUpdateData(false);
      setLoading(false);
      if (!data.user) {
        // NEW USER
        let { uid, email, displayName } = { ...user } as User;
        const newUser = { id: uid, email: email || '', displayName: displayName || '', allFolders: data.allFolders, allProjects: data.allProjects };
        console.log({ newUser });

        setContextData({ ...data, user: newUser });
        updateDbItem({
          user: newUser,
          callUpdate: () => {},
        });
      }
    });
  }

  // AUTH STATE LISTENER
  onAuthStateChanged(auth, user => {
    // console.log('onAuthStateChanged', { user });
    if (user) {
      if (!contextData && !loading) {
        fetchData(user);
      }
    } else {
      // User is signed out
      setContextData(null);
      localStorage.clear();
    }
    return {};
  });

  // UPDATE DATA LISTENER
  useEffect(() => {
    if (updateData) {
      localStorage.clear();
      fetchData();
    }
  }, [updateData]);

  const onSignOut = () => {
    signOut(auth);
  };

  const onMessage = useCallback((type: NoticeType, msg: string) => {
    messageApi?.open({
      type,
      content: msg,
    });
  }, []);

  // ON ERROR
  useEffect(() => {
    if (error) {
      onMessage('error', `${error}`);
      setError('');
    }
  }, [error, onMessage]);
  // ON SUCCESS
  useEffect(() => {
    if (success) {
      onMessage('success', `${success}`);
      setSuccess('');
    }
  }, [success, onMessage]);

  const contextValue = { contextData, setContextData, updateData, setUpdateData, loading, onSignOut, setSuccess, setError };

  return (
    <AuthContext.Provider value={contextValue}>
      {children} {msgContextHolder}
    </AuthContext.Provider>
  );
};

export function useAuthContext(): AuthContextType {
  const context = useContext(AuthContext) as AuthContextType;
  if (context === undefined) {
    throw new Error('context is undefined');
  }
  return context;
}
