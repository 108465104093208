import { Button, Input } from 'antd';
import { InputEvent, Project, Row, RowType, TabType } from '../../../types';
import { RefObject, useRef, useState } from 'react';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Trash } from '@phosphor-icons/react';
import { getInputProps } from '../utils';
import { useProjectContext } from '../../../context/ProjectContext';
import EditorActions from './EditorActions';
import getUniqueId from '../../../utils/getUniqueId';
import { useLocation } from 'react-router-dom';

type EditorViewProps = {
  tabType: TabType;
};

export default function EditorView({ tabType }: EditorViewProps) {
  const bottomRef = useRef() as RefObject<HTMLDivElement>;
  const { project, onProjectChange, editMode } = useProjectContext();
  const [reorder, setReorder] = useState(false);
  const isPattern = tabType === 'pattern';
  const location = useLocation();
  const onAdd = location.pathname.includes('add');

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  // ADD ROW
  const addNewRow = (type: RowType = 'row') => {
    const currProject: Project = { ...project };
    const toAdd: Row = {
      label: isPattern ? 'Row ' : '',
      value: '',
      done: false,
      type,
      id: getUniqueId(),
    };

    currProject[tabType].push(toAdd);
    onProjectChange({ ...currProject });

    // scroll to row/bottom
    setTimeout(() => {
      bottomRef?.current!.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  // DRAG REORDER
  const handleReorder = (event: DragEndEvent) => {
    const { active, over } = event;
    const currProject = { ...project };
    let currRows = [...currProject[tabType]];
    if (!over) return;

    if (active.id !== over.id) {
      const oldIndex = currRows.map(r => r.id).indexOf(active.id);
      const newIndex = currRows.map(r => r.id).indexOf(over.id);
      const moved = arrayMove(currRows, oldIndex, newIndex);
      currProject[tabType] = moved;
      onProjectChange({ ...currProject });
    }
  };

  // DELETE
  const handleDeleteRow = (index: number) => {
    let currProject: Project = { ...project };
    currProject[tabType] = currProject[tabType].filter((_r: Row, i: number) => i !== index);
    onProjectChange({ ...currProject });
  };

  // ROW CHANGE
  const handleRowChange = (e: InputEvent, i: number, field: string) => {
    e.preventDefault();
    const currProject: Project = { ...project } as Project;
    if (currProject[tabType][i]) {
      if (field === 'value') {
        currProject[tabType][i].value = e.target.value;
      } else {
        currProject[tabType][i].label = e.target.value;
      }
    }
    onProjectChange({ ...currProject });
  };

  //   SORTABLE ROW
  const SortableRow = ({ row, i }: { row: Row; i: number }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: row.id });
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
      <div key={i} className="row-display sortable" ref={setNodeRef} style={style} {...attributes} {...listeners}>
        {row.type === 'row' && <span className="row-label">{row.label}</span>}
        <span className={`row-value ${row.type !== 'row' ? 'no-label' : ''}`}>{row.value}</span>
      </div>
    );
  };

  let placeholder = '';
  switch (tabType) {
    case 'specs':
      placeholder = 'Spec name';
      break;
    case 'abbreviations':
      placeholder = 'Abbreviation';
      break;
    default:
      placeholder = 'Row';
  }

  return (
    <>
      {/* ACTION BTNS */}
      <EditorActions reorder={reorder} tabType={tabType} setReorder={setReorder} addNewRow={addNewRow} />

      <div className={`project-content ${editMode || onAdd ? 'edit-mode' : ''}`}>
        {/* REORDER VIEW */}
        {reorder && (
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleReorder}>
            <SortableContext items={project[tabType]} strategy={verticalListSortingStrategy}>
              {project[tabType].map((row, i) => {
                return <SortableRow key={i} row={row} i={i} />;
              })}
            </SortableContext>
          </DndContext>
        )}

        {/* DEFAULT VIEW */}
        {!reorder &&
          project[tabType].map((row, i) => {
            const labelProps = getInputProps({ type: 'label', row, i, handleRowChange });
            const valueProps = getInputProps({ type: 'value', row, i, handleRowChange });
            return (
              <div className="row-display" key={i}>
                {/* label */}
                {row.type === 'row' && <Input {...labelProps} className="row-label" placeholder={placeholder} />}

                {/* value */}
                {row.type === 'note' ? (
                  <Input.TextArea {...valueProps} className={`row-value no-label`} autoSize={{ minRows: 2 }} />
                ) : (
                  <Input {...valueProps} className={`row-value ${row.type !== 'row' ? 'no-label' : ''}`} />
                )}

                {/* delete */}
                <Button className="delete-btn" onClick={() => handleDeleteRow(i)} type="text" icon={<Trash size={20} weight="fill" />} danger />
              </div>
            );
          })}
        <div ref={bottomRef}></div>
      </div>
    </>
  );
}
