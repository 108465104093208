import { Envelope, Pencil, UserCircle } from '@phosphor-icons/react';
import { Flex, Input, Tabs, Typography } from 'antd';
import { useState } from 'react';
import { auth } from '../../firebase';
import GlobalModal from './GlobalModal';
import '../../styles/UserProfile.scss';
import { updatePassword, updateProfile } from 'firebase/auth';
import { useAuthContext } from '../../context/AuthContext';
import { AuthUser, InputEvent } from '../../types';
import { updateDbItem } from '../../utils/dbActions';
const { Text } = Typography;

export default function EditUser({ closeModal }: { closeModal: () => void }) {
  const { contextData, setUpdateData, setSuccess, setError } = useAuthContext();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [currUser, setCurrUser] = useState<AuthUser | null>(contextData?.user || null);
  const [newPassword, setNewPassword] = useState({ password: '', confirmed: '' });
  const [activeTab, setActiveTab] = useState('user');
  const [pwMatches, setPWMatches] = useState(false);

  const saveUser = () => {
    if (auth.currentUser && currUser) {
      updateProfile(auth.currentUser, {
        displayName: currUser.displayName,
      })
        .then(() => {
          updateDbItem({ user: currUser, callUpdate: () => setUpdateData(true), thenActions: () => closeModal() });

          setSuccess('Saved user profile');
        })
        .catch(error => {
          console.error(error);
          setError("Could not save user's name");
        });

      // updateEmail(auth.currentUser, currUser.email)
      //   .then(() => {
      //     updateDbItem({ user: currUser, callUpdate: () => setUpdateData(true), thenActions: () => closeModal() });
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     setError('Could not save email');
      //   });
    }
  };

  const onResetPassword = () => {
    if (auth.currentUser) {
      updatePassword(auth.currentUser, newPassword.password)
        .then(() => {
          setSuccess('Succesfully reset password');
        })
        .catch(error => {
          console.error(error);
          setError('Could not reset password');
        });
    }
  };

  const handleUserChange = (e: InputEvent) => {
    if (currUser) {
      setCurrUser({ ...currUser, [e.target.name]: e.target.value });
      setUnsavedChanges(true);
    }
  };
  const handlePWChange = (e: InputEvent) => {
    const updated = { ...newPassword, [e.target.name]: e.target.value.trim() };
    setNewPassword({ ...newPassword, [e.target.name]: e.target.value.trim() });
    console.log({ updated });

    if (updated.password.length > 7 && updated.password === updated.confirmed) {
      setPWMatches(true);
    } else {
      setPWMatches(false);
    }
  };

  const userContent = (
    <div className="user-profile-editor">
      <Input value={`${currUser?.displayName}`} onChange={handleUserChange} prefix={<UserCircle size={16} />} name="displayName" placeholder="Name" />
      <Input value={`${currUser?.email}`} onChange={handleUserChange} prefix={<Envelope size={16} />} name="email" placeholder="Email" disabled />
    </div>
  );
  const resetPassword = (
    <div className="pw-editor">
      <Input.Password value={newPassword.password} onChange={handlePWChange} name="password" placeholder="New Password" />
      <Text className="pw-hint" disabled>
        Password must be at least 8 characters
      </Text>
      <Input.Password value={newPassword.confirmed} onChange={handlePWChange} name="confirmed" placeholder="Confirm Password" style={{ marginTop: '1em' }} />
    </div>
  );
  const tabs = (
    <Tabs
      // defaultActiveKey="password"
      onChange={tab => setActiveTab(tab)}
      items={[
        {
          label: 'User',
          key: 'user',
          children: userContent,
        },
        {
          label: 'Reset Password',
          key: 'password',
          children: resetPassword,
        },
      ]}
    />
  );

  const modal = {
    title: (
      <Flex align="center" className="edit-user-title">
        <Pencil size={28} weight="fill" style={{ marginRight: '.5em' }} />
        <h3>Edit Profile </h3>
      </Flex>
    ),
    onOk: () => {
      activeTab === 'user' ? saveUser() : onResetPassword();
    },
    okText: activeTab === 'user' ? 'Save' : 'Reset Password',
    content: tabs,
  };
  return <GlobalModal {...modal} closeModal={closeModal} disableOk={activeTab === 'user' ? !unsavedChanges : !pwMatches} />;
}
