import { Envelope, Pencil, User, UserCircle } from '@phosphor-icons/react';
import { Button, Divider, Dropdown, Flex, MenuProps, Typography } from 'antd';
import '../styles/UserProfile.scss';
import { useState } from 'react';
import { EditUserModal } from './modals';
import { useAuthContext } from '../context/AuthContext';
const { Text } = Typography;

export default function UserProfile() {
  const [openModal, setOpenModal] = useState(false);
  const { contextData } = useAuthContext();
  const user = contextData?.user;

  const userMenu = [
    {
      key: 'name',
      icon: <User weight="fill" size={16} />,
      label: user?.displayName,
    },
    {
      key: 'email',
      icon: <Envelope weight="bold" size={16} />,
      label: user?.email,
    },
  ];
  const items: MenuProps['items'] = userMenu.map(i => {
    return {
      key: i.key,
      label: (
        <div className="user-menu-item">
          <Flex align="center">
            {i.icon}
            <Text>{i.label}</Text>
          </Flex>
          <Divider />
        </div>
      ),
    };
  });

  items.push({
    key: 'edit',
    label: (
      <Button onClick={() => setOpenModal(true)} icon={<Pencil size={16} weight="bold" />} type="primary" size="small" style={{ width: '100%' }}>
        Edit profile
      </Button>
    ),
    onClick: () => {},
  });

  return (
    <>
      <Dropdown menu={{ items }}>
        <Button icon={<UserCircle size={28} weight="fill" />} shape="circle" type="text" className="user-profile-btn" />
      </Dropdown>
      {openModal && <EditUserModal closeModal={() => setOpenModal(false)} />}
    </>
  );
}
