import { Yarn } from '@phosphor-icons/react';
import { Flex } from 'antd';

export default function Empty({ msg }: { msg: string }) {
  return (
    <Flex vertical align="center" className="not-found">
      <Yarn size={50} />
      <h3>{msg}</h3>
    </Flex>
  );
}
