import '../styles/Knitting.scss';
import { useMemo, useState } from 'react';
import { Folder, Project, ProjectListItemProps, ResponseData, InputEvent } from '../types';
import { useAuthContext } from '../context/AuthContext';
import { ProjectListItem } from '../components/project/index';
import { Flex, Input } from 'antd';
import SearchIcon from '@mui/icons-material/Search';
import Empty from '../components/Empty';

export default function MyProjects() {
  const { contextData } = useAuthContext();
  const data: ResponseData = contextData as ResponseData;

  const { allProjects, allFolders } = data;
  const [search, setSearch] = useState('');

  // SEARCH
  const onSearch = (e: InputEvent) => {
    setSearch(e.target.value.toLowerCase().trim());
  };

  // PROJECTS
  const filteredProjects: Project[] = useMemo(() => {
    if (search.length < 1) {
      return allProjects?.filter((p: Project) => !p.folder);
    } else {
      return allProjects.filter((p: Project) => p.name.toLowerCase().trim().includes(`${search}`));
    }
  }, [allProjects, search]);

  // FOLDERS
  const filteredFolders: Folder[] = useMemo(() => {
    if (search.length < 1) {
      return [...allFolders];
    } else {
      return allFolders.filter((f: Folder) => f.name.toLowerCase().trim().includes(`${search}`));
    }
  }, [allFolders, search]);

  return (
    <div className="container">
      {/* SEARCH */}
      <div className="search-input">
        <Input onChange={onSearch} prefix={<SearchIcon />} size="large" allowClear />
      </div>
      {/* FOLDERS */}
      <Flex wrap className="thumbnail-container">
        {filteredFolders.map((folder: Folder) => {
          const props: ProjectListItemProps = { item: folder, allFolders, isFolder: true };
          return <ProjectListItem {...props} key={folder.id} />;
        })}
      </Flex>

      {/* PROJECT  */}
      <Flex wrap className="thumbnail-container">
        {filteredProjects.map((project: Project) => {
          const props: ProjectListItemProps = { item: project, allFolders };
          return <ProjectListItem {...props} key={project.id} />;
        })}
      </Flex>
      {!filteredProjects.length && !filteredFolders.length && <Empty msg="No projects or folders found" />}
    </div>
  );
}
