import { Plus } from '@phosphor-icons/react';
import { Button, Flex } from 'antd';
import { RowType, TabType } from '../../../types';
import { useProjectContext } from '../../../context/ProjectContext';
import { Dispatch, SetStateAction } from 'react';

type EditorActionsProps = {
  reorder: boolean;
  tabType: TabType;
  setReorder: Dispatch<SetStateAction<boolean>>;
  addNewRow: (type?: RowType) => void;
};
export default function EditorActions({ reorder, tabType, setReorder, addNewRow }: EditorActionsProps) {
  const { project, isMobile, tabHeight } = useProjectContext();

  const isPattern = tabType === 'pattern';
  const hasRows = project[tabType].length > 0;
  let translateX = 0;
  console.log({ tabHeight });

  if (isMobile) {
    translateX = hasRows ? tabHeight - 35 : 0;
  } else {
    translateX = hasRows ? tabHeight - 35 : 0;
  }

  const addActions = (
    <Flex className={`add-actions`} align="center" style={{ transform: `translate(0px, ${hasRows ? tabHeight - 35 : 0}px)` }}>
      <Button onClick={() => addNewRow()} icon={<Plus size={16} weight="bold" />} type="text">
        {isPattern ? 'row' : tabType.substring(0, tabType.length - 1)}
      </Button>
      <Button onClick={() => addNewRow('note')} icon={<Plus size={16} weight="bold" />} type="text">
        note
      </Button>
      <Button onClick={() => addNewRow('header')} icon={<Plus size={16} weight="bold" />} type="text">
        header
      </Button>
    </Flex>
  );
  return (
    <>
      {/* <Flex justify="end" align="center" className={`editor-actions`}>
        {hasRows && (
          <Button
            onClick={() => setReorder(!reorder)}
            icon={reorder ? <Check size={14} weight="bold" /> : <ArrowsOutCardinal size={16} weight="bold" />}
            type={reorder ? 'default' : 'primary'}
            shape="circle"
            size="small"
          />
        )}
    
      </Flex> */}
      {!reorder && <>{addActions}</>}
    </>
  );
}
