import { doc, setDoc, writeBatch } from 'firebase/firestore';
import { AuthUser, Folder, Project } from '../types';
import { db } from '../firebase';

type ActionProps = {
  user: AuthUser | null | undefined;
  callUpdate: Function;
  thenActions?: () => void;
  onError?: () => void;
};

interface AddFolderProps extends ActionProps {
  newFolder: Folder;
}
interface AddProjectProps extends ActionProps {
  newProject: Project;
}
interface UpdateProjectProps extends ActionProps {
  project: Project;
}
interface UpdateFolderProps extends ActionProps {
  folder: Folder;
}
interface RemoveProps extends ActionProps {
  id: string;
  itemType: 'folder' | 'project';
}

export const updateDbItem = async ({ user, thenActions, callUpdate, onError }: ActionProps): Promise<void> => {
  try {
    if (user?.id) {
      await setDoc(doc(db, 'users', user.id), user).then(() => {
        if (thenActions) thenActions();
        callUpdate();
      });
    }
  } catch (e) {
    console.error(e);
    if (onError) onError();
  }
};

export const addFolder = async ({ user, newFolder, thenActions, callUpdate, onError }: AddFolderProps): Promise<void> => {
  if (user) {
    const updated: AuthUser = { ...user } as AuthUser;
    updated.allFolders.push(newFolder);
    updateDbItem({ user: updated, thenActions, callUpdate, onError });
  }
};
export const addProject = async ({ user, newProject, thenActions, callUpdate, onError }: AddProjectProps): Promise<void> => {
  if (user) {
    const updated: AuthUser = { ...user } as AuthUser;
    updated.allProjects.push(newProject);
    updateDbItem({ user: updated, thenActions, callUpdate, onError });
  }
};
export const updateProject = async ({ user, project, thenActions, callUpdate, onError }: UpdateProjectProps): Promise<void> => {
  if (user) {
    const updated: AuthUser = { ...user } as AuthUser;
    updated.allProjects = updated.allProjects.map((p: Project) => {
      if (p.id === project.id) {
        return project;
      }
      return p;
    });
    updateDbItem({ user: updated, thenActions, callUpdate, onError });
  }
};
export const updateFolder = async ({ user, folder, thenActions, callUpdate, onError }: UpdateFolderProps): Promise<void> => {
  if (user) {
    const updated: AuthUser = { ...user } as AuthUser;
    updated.allFolders = updated.allFolders.map((f: Folder) => {
      if (f.id === folder.id) {
        return { ...folder };
      } else {
        return f;
      }
    });
    updateDbItem({ user: updated, thenActions, callUpdate, onError });
  }
};

export const removeItem = async ({ user, id, itemType, thenActions, callUpdate, onError }: RemoveProps): Promise<void> => {
  if (user) {
    const updated: AuthUser = { ...user } as AuthUser;
    if (itemType === 'project') {
      updated.allProjects = updated.allProjects.filter((p: Project) => p.id !== id);
    } else if (itemType === 'folder') {
      updated.allFolders = updated.allFolders.filter((f: Folder) => f.id !== id);
    }
    updateDbItem({ user: updated, thenActions, callUpdate, onError });
  }
};

// export const deleteDbItem = async ({ id, thenActions, callUpdate }: ActionProps): Promise<void> => {
//   try {
//     await deleteDoc(doc(db, 'users', id)).then(() => {
//       if (thenActions) thenActions();
//       callUpdate();
//     });
//   } catch (e) {
//     console.log(e);
//   }
// };

export const deleteFolderWithProjects = async ({
  folderId,
  projectIds,
  thenActions,
  callUpdate,
  onError,
}: {
  folderId: string;
  projectIds: string[];
  callUpdate: Function;
  thenActions?: () => void;
  onError?: () => void;
}): Promise<void> => {
  try {
    const batch = writeBatch(db);
    batch.delete(doc(db, 'folders', folderId));
    projectIds.forEach((id: string) => {
      batch.delete(doc(db, 'projects', id));
    });

    await batch.commit().then(() => {
      if (thenActions) thenActions();
      callUpdate();
    });
  } catch (e) {
    console.log(e);
    if (onError) onError();
  }
};
