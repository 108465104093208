import { Checkbox } from 'antd';
import { Row, TabType } from '../../types';
import { useProjectContext } from '../../context/ProjectContext';
import Empty from '../Empty';

type RowsProps = {
  // project: Project;
  toggleDone?: (i: number) => void;
  tabType: TabType;
};

export default function RowsDisplay({ toggleDone, tabType }: RowsProps) {
  const { project } = useProjectContext();

  if (project[tabType].length < 1) {
    return <Empty msg={`No ${tabType} added yet`} />;
  }
  const isPattern = tabType === 'pattern';

  const getValueClasses = (row: Row) => {
    let classes = 'row-value';
    if (row.done) {
      classes = `${classes} done`;
    }
    if (row.type !== 'row') {
      classes = `${classes} no-label`;
    }
    return classes;
  };

  return (
    <>
      {project[tabType].map((row: Row, i: number) => {
        const isHeader = row.type === 'header';
        const isPatternRow = row.type === 'row';
        return (
          <div className={`row-display ${isPatternRow ? 'hover' : 'no-label'}`} key={i}>
            {isPattern && row.type === 'row' && <Checkbox className="is-done-cb" checked={row.done} onClick={toggleDone ? () => toggleDone(i) : undefined} />}

            {row.type === 'row' && (
              <span className={`row-label ${row.done ? 'done' : ''}`} style={{ fontWeight: 600 }}>
                {row.label}
              </span>
            )}
            {isHeader ? <h3>{row.value}</h3> : <span className={getValueClasses(row)}>{row.value}</span>}
          </div>
        );
      })}
    </>
  );
}
