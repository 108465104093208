import { ConfigProvider, Layout } from 'antd';
import Header from '../components/Header';
import themeConfig from '../utils/themeConfig';
import { Content } from 'antd/es/layout/layout';
import { ReactNode } from 'react';
import { useAuthContext } from '../context/AuthContext';
import { useParams } from 'react-router-dom';
import { Folder, Project } from '../types';
import TopNav from '../components/TopNav';
import Login from '../components/Login';
import { useProjectContext } from '../context/ProjectContext';

export default function PageLayout({ children }: { children: ReactNode }) {
  const { contextData, loading } = useAuthContext();
  const { isMobile } = useProjectContext();
  let allProjects: Project[] = contextData?.allProjects || [];
  let allFolders: Folder[] = contextData?.allFolders || [];
  let { folderId, projectId } = useParams();
  const project = allProjects.find((p: Project) => p.id === projectId);
  const folder = allFolders.find((f: Folder) => f.id === folderId);

  const siderBarWidth = 160;

  return (
    <ConfigProvider theme={themeConfig}>
      {contextData?.user && (
        <Layout style={{ height: '100vh', marginTop: '' }}>
          <Header siderBarWidth={siderBarWidth} />
          <Layout style={{ marginInlineStart: isMobile ? 0 : siderBarWidth }}>
            <TopNav projectName={project?.name} folderName={folder?.name} />
            <Content>{children}</Content>
          </Layout>
        </Layout>
      )}
      {!contextData?.user && <Login />}
    </ConfigProvider>
  );
}
