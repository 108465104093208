import { TabType } from '../../types';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { EditorView } from './index';
import RowsDisplay from './RowsDisplay';
import { useProjectContext } from '../../context/ProjectContext';

type PatternTabProps = {
  toggleDone?: (i: number) => void;
  tabType: TabType;
};

export default function PatternTab({ toggleDone, tabType }: PatternTabProps) {
  const { tabHeight, isMobile } = useProjectContext();
  const location = useLocation();
  const onAdd = location.pathname.includes('add');
  let [searchParams] = useSearchParams();

  const [editMode, setEditMode] = useState(searchParams.get('edit') === 'true');

  useEffect(() => {
    if (searchParams.get('edit') === 'true') {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  }, [searchParams]);

  return (
    <div style={{ height: `${tabHeight - (editMode ? 35 : 0)}px` }} className={onAdd || editMode ? 'editor-container' : 'project-content'}>
      {onAdd || editMode ? <EditorView tabType={tabType} /> : <RowsDisplay toggleDone={toggleDone} tabType={tabType} />}
    </div>
  );
}
