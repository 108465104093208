import { theme } from 'antd';

const token = {
  colorPrimary: '#171c5d',
  colorSecondary: 'red',
};
const components = {
  Menu: {
    itemBg: 'transparent',
    itemPaddingInline: '1px',
    itemSelectedColor: '#fff',
    itemSelectedBg: '#ffffff47',
    itemColor: '#fff',
    // itemHoverBg: '#fff',
    itemHoverColor: '#fff',
  },
  Breadcrumb: {
    separatorColor: `${token.colorPrimary}c7`,
    linkColor: `${token.colorPrimary}c7`,
    lastItemColor: token.colorPrimary,
    linkHoverColor: token.colorPrimary,
    // separatorColor: 'rgb(255 255 255 / 80%)',
    // linkColor: 'rgb(255 255 255 / 80%)',
    // lastItemColor: '#fff',
    // linkHoverColor: '#fff',
  },
  Layout: {
    siderBg: token.colorPrimary,
    bodyBg: '#fff',
  },
  Tabs: {
    verticalItemPadding: '.5em 1em',
    itemSelectedColor: '#7178bc',
    itemColor: '#999',
    // cardBg: '#eee',
    cardGutter: 5,
  },
};

export default {
  algorithm: theme.defaultAlgorithm,
  components,
  token,
};
