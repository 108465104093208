import { FolderSimple, House } from '@phosphor-icons/react';
import { Breadcrumb, Flex } from 'antd';
import '../styles/Knitting.scss';
import { useLocation, useParams } from 'react-router-dom';
import { useProjectContext } from '../context/ProjectContext';
import UserProfile from './UserProfile';

type BreadcrumbMenu = {
  title: JSX.Element;
  href?: string;
};

export default function TopNav({ folderName, projectName }: { folderName?: string; projectName?: string }) {
  let { folderId } = useParams();
  const location = useLocation();
  const { isMobile } = useProjectContext();

  const onFolderView = folderName && !projectName;
  const onFolderProjectView = folderName && projectName;
  const onAdd = location.pathname.includes('add');
  const onHome = location.pathname === '/my-projects';
  const folderTitle = (
    <>
      <FolderSimple size={18} weight="fill" />
      <span>{folderName}</span>
    </>
  );
  const items: BreadcrumbMenu[] = [
    {
      href: '/my-projects',
      title: <House size={18} weight="fill" />,
    },
  ];

  if (onFolderView) {
    // home / FOLDER

    if (onAdd) {
      items.push({
        href: `/my-projects/folder/${folderId}`,
        title: folderTitle,
      });
      items.push({
        title: <span>Add Project</span>,
      });
    } else {
      items.push({
        title: folderTitle,
      });
    }
  } else if (onFolderProjectView) {
    // home / FOLDER / PROJECT
    items.push({
      href: `/my-projects/folder/${folderId}`,
      title: folderTitle,
    });
    items.push({
      title: <span>{projectName}</span>,
    });
  } else if (projectName) {
    // home / PROJECT
    items.push({
      title: <span>{projectName}</span>,
    });
  } else if (onAdd) {
    items.push({
      title: <span>Add Project</span>,
    });
  }

  return !isMobile || (!onHome && isMobile) ? (
    <Flex justify="space-between" align="center" className="top-nav">
      <Breadcrumb className="bread-crumbs" items={items} />
      {!isMobile && <UserProfile />}
    </Flex>
  ) : (
    <></>
  );
}
