import { useNavigate } from 'react-router-dom';
import { useProjectContext } from '../../context/ProjectContext';
import GlobalModal from './GlobalModal';

export default function UnsavedChanges({ closeModal }: { closeModal: () => void }) {
  const navigate = useNavigate();
  const { saveProject } = useProjectContext();

  const modal = {
    title: 'You have unsaved changes',
    onCancel: () => navigate('/my-projects'),
    onOk: () => {
      saveProject();
      navigate('/my-projects');
    },
    okText: 'Save Project',
    cancelText: 'Discard Changes',
  };

  return <GlobalModal {...modal} closeModal={closeModal} />;
}
