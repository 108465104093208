import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import GlobalModal from './GlobalModal';

export default function Signout({ closeModal }: { closeModal: () => void }) {
  const { onSignOut } = useAuthContext();
  const navigate = useNavigate();

  const modal = {
    title: 'Are you sure you want to sign out?',
    onOk: () => {
      navigate('/my-projects');
      onSignOut();
    },
    okText: 'Sign Out',
  };
  return <GlobalModal {...modal} closeModal={closeModal} />;
}
