import { DotsThreeOutlineVertical, Folder as FolderIcon, Yarn } from '@phosphor-icons/react';
import { Button, Flex, Dropdown, MenuProps, Modal, Input } from 'antd';
import { Folder, Project, ProjectListItemProps, InputEvent, ResponseData } from '../../types';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { deleteFolderWithProjects, removeItem, updateFolder, updateProject } from '../../utils/dbActions';
import { useAuthContext } from '../../context/AuthContext';

export default function ProjectListItem({ item, isFolder }: ProjectListItemProps) {
  let location = useLocation();
  const navigate = useNavigate();

  const { setUpdateData, contextData, setError, setSuccess } = useAuthContext();
  const { allFolders, allProjects } = contextData as ResponseData;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [listItem, setListItem] = useState<Project | Folder>(item);
  const onFolderView = location.pathname.includes('folder');

  const thenActions = () => {
    setIsModalOpen(false);
    if (modalType === 'moveToFolder') {
      setSuccess(`Project ${onFolderView ? 'removed from folder' : 'moved to folder'}`);
    }
  };
  const callUpdate = () => setUpdateData(true);
  const onError = () => setError(`Could not save ${isFolder ? 'folder' : 'project'}`);

  // OPEN MODAL
  const openModal = (type: string) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  // CLOSE MDOAL
  const closeModal = () => {
    setModalType('');
    setIsModalOpen(false);
  };

  // SAVE SELECTED FOLDER
  function selectFolder(folderId: string) {
    const updated: Project = { ...item } as Project;
    updated.folder = folderId;
    updateProject({ user: contextData?.user, project: updated, thenActions, callUpdate, onError });
  }

  // REMOVE FOLDER
  function removeFromFolder() {
    const updated: Project = { ...item } as Project;
    delete updated.folder;
    updateProject({ user: contextData?.user, project: updated, thenActions, callUpdate, onError });
  }

  // SAVE FOLDER NAME
  function saveFolderName() {
    const updated: Folder = { ...item } as Folder;
    updated.name = listItem.name;
    updateFolder({ user: contextData?.user, folder: updated, thenActions, callUpdate, onError });
  }

  // DELETE
  function deleteItem(projectsInFolder?: Project[]) {
    if (projectsInFolder?.length) {
      // delete folder and projects within
      const projIds = projectsInFolder.map((p: Project) => p.id);
      deleteFolderWithProjects({ folderId: item.id, projectIds: projIds, thenActions, callUpdate, onError });
    } else {
      // delete single item
      removeItem({
        user: contextData?.user,
        itemType: isFolder ? 'folder' : 'project',
        id: item.id,
        thenActions,
        callUpdate,
        onError: () => setError(`Could not delete ${isFolder ? 'folder' : 'project'}`),
      });
    }
  }

  // FOLDER NAME CHANGE
  const onFolderViewNameChange = (e: InputEvent) => {
    e.preventDefault();
    setListItem({ ...listItem, name: e.target.value });
  };

  // GET MODAL CONTENT
  const getModal = () => {
    let modal: {
      title: string;
      body: JSX.Element;
      footer: JSX.Element[];
    } = {
      title: '',
      body: <></>,
      footer: [],
    };

    switch (modalType) {
      //  MOVE TO FOLDER
      case 'moveToFolder':
        modal.title = 'Select Folder';
        modal.body = (
          <>
            {allFolders.map((folder: Folder) => {
              return (
                <div onClick={() => selectFolder(folder.id)} className="thumbnail flat" key={folder.id}>
                  <Flex className="icon-name">
                    <FolderIcon weight="fill" size={30} />
                    <span>{folder.name}</span>
                  </Flex>
                </div>
              );
            })}
          </>
        );

        break;
      // RENAME FOLDER
      case 'renameFolder':
        modal.title = 'Rename Folder';
        modal.body = <Input value={listItem.name} onChange={onFolderViewNameChange} />;
        modal.footer = [
          <Button onClick={saveFolderName} type="primary" disabled={!listItem.name} key="1">
            Save
          </Button>,
        ];
        break;
      // DELETE ITEM
      case 'delete':
        let projectsInFolder: Project[] = [];
        if (isFolder) {
          projectsInFolder = allProjects.filter((p: Project) => p.folder === item.id);
        }
        modal.title = `Are you sure you want to delete this ${isFolder ? 'folder' : 'project'}?`;
        modal.body = (
          <div className="thumbnail flat no-click">
            <Flex className="icon-name">
              {isFolder ? <FolderIcon weight="fill" size={30} /> : <Yarn weight="fill" size={30} />}
              <span>{item.name}</span>
            </Flex>
            {/* show projects inside folder to be deleted */}
            {projectsInFolder && (
              <div className="projects-in-folder">
                {projectsInFolder.map((p: Project) => {
                  return (
                    <Flex className="icon-name" key={p.id}>
                      <Yarn weight="fill" size={20} /> <span>{p.name}</span>
                    </Flex>
                  );
                })}
              </div>
            )}
          </div>
        );
        modal.footer = [
          <Button onClick={() => deleteItem(projectsInFolder)} type="primary" danger key="1">
            Delete
          </Button>,
          <Button onClick={closeModal} key="2">
            Cancel
          </Button>,
        ];
        break;
      default:
        break;
    }
    return modal;
  };

  const navigateTo = () => {
    const project = item as Project;
    if (isFolder) {
      navigate(`folder/${item.id}`);
    } else if (project.folder) {
      navigate(`/my-projects/folder/${project.folder}/${item.id}`);
    } else {
      navigate(`view/${item.id}`);
    }
  };

  // MENU ITEMS
  const items: MenuProps['items'] = [];
  if (isFolder) {
    items.push({
      key: 'rename',
      label: 'Rename',
      onClick: () => openModal('renameFolder'),
    });
  } else {
    if (onFolderView) {
      items.push({
        key: 'remove',
        label: 'Remove from folder',
        onClick: removeFromFolder,
      });
    } else {
      items.push({
        key: 'move',
        label: 'Move to folder',
        onClick: () => openModal('moveToFolder'),
      });
    }
  }
  items.push({
    key: 'delete',
    label: 'Delete',
    onClick: () => openModal('delete'),
  });

  return (
    <Flex className="thumbnail" justify="space-between" align="center">
      <Flex onClick={navigateTo} className="icon-name">
        {isFolder ? <FolderIcon weight="fill" size={30} /> : <Yarn weight="fill" size={30} />}
        <span>{item.name}</span>
      </Flex>

      {/* MENU */}
      <Dropdown menu={{ items }}>
        <Button icon={<DotsThreeOutlineVertical size={20} weight="fill" />} shape="circle" type="text" />
      </Dropdown>

      {/* SELECT FOLDER MODAL */}
      <Modal title={getModal().title} open={isModalOpen} onCancel={closeModal} footer={[]}>
        <div style={{ marginTop: '1em' }}>{getModal().body}</div>
        <Flex className="modal-footer">{getModal().footer.map((e: JSX.Element) => e)}</Flex>
      </Modal>
    </Flex>
  );
}
